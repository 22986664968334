/* GLOBAL STYLES
   ----------------------------- */

body {
    background-color: $globalBackgroundColor !important;
    font-family: $globalFontFamily;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.8rem;
}

.form-control{
    font-size: 0.8rem;
}

.btn{
    font-size: 10px !important;
}

hr {
    border-color: $hrColor;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

.no-radius-b-l {
    border-radius: 0 0 4px 4px;
}

b,
strong {
    font-weight: 600;
}

input {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    padding: 8px;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
}


.mini-md-font {
    font-size: 14px;
}

.mini-sm-font {
    font-size: 12px;
}

.mini-font {
    font-size: 10px;
}

.mini-xs-font {
    font-size: 9px;
}

.dropzone:hover {
    background: #E9EBED !important;
    cursor: pointer;
}

.media-select {
    cursor: pointer;
}

.app-header-page {
    margin-left: -30px;
    padding: 30px;
    min-height: 60px;
    position: fixed;
    top: 0;
    background: white;
    display: flex;
    z-index: 80;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 30px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: width 0.1s ease, left 0.1s ease;
    width: calc(100% - 200px);

    .side-menu-collapsed & {
        width: calc(100% - 50px);
    }

    @media screen and (max-width: 992px) {
        width: 100%;
    }
}

.ck-editor__main .ck-editor__editable {
    min-height: 200px;
}

.mw-200{
    max-width: 200px;
}
.mw-350 {
    max-width: 350px;
}

.rotating {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.card.fade-card{
    background: rgb(255, 255, 255, 0.6);
}

.custom-control-label{
    padding-top: 0.25rem !important;
}

.download-wrapper{
    @media (max-width: 992px) {
        text-align: center;
    }
    margin-top: -90px;
    img{
        max-width: 200px;
        margin-bottom: 30px;
    }
    
}