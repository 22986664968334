/* WIDTH UTILITY STYLES
   ----------------------------- */

.w-full {
    width: 100%;
}
.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block; }

.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block; }

.w-20 {
  width: 100px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center; }

.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center; }

.w-32 {
  width: 32px;
  height: 32px;
  display: inline-block;
  text-align: center; }

.w-40 {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center; }

.w-48 {
  width: 48px;
  height: 48px;
  display: inline-block;
  text-align: center; }

.w-56 {
  width: 56px;
  height: 56px;
  display: inline-block;
  text-align: center; 
}
.w-100 {
  width: 100px;
  height: 100px;
  display: inline-block;
  text-align: center; 
}

.w-15-percent{
  width: 15%;
}

.mw-90 {
  max-width: 90%;
}

.fit-text {
  width: 50%;  
}

@media (max-width: 769.98px) {
  .fit-text {
    width: 100%;
  }
}

.col-w-25 {
  width: 25%;
}

.col-w-50 {
  width: 50%;
}

@media (max-width: 769.98px) {
  .col-w-sm-100 {
    width: 100%;
  }
}

@media (max-width: 769.98px) {
  .top-right-sm {
    width: 100%;
  }
}
