/* UTILITY STYLES
   ----------------------------- */

.clear {
    display: block;
    overflow: hidden;
}

.full-bleed {
    margin: -$panelPadding;
}

.img__contain {
    object-fit: contain;
}

.ck-editor__editable {
    min-height: 400px;
}

.ck-sticky-panel__content {
    display: none;
}

.drag-handle {
    content: "";
    width: 32px;
    height: 30px;
    display: flex;
    overflow: hidden;
    line-height: 7px;
    padding: 0px 4px;
    cursor: move;
    margin: 0 auto;
    font-size: 35px;
    letter-spacing: 2px;
    color: #B2B2B2;

    &:after {
        content: '... ...';
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-bottom: 0 !important;
    margin-left: 10px;
    margin-right: 15px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .right-border{
    border-right: 1px #D2D4DA solid;
  }


  .draggable-back, .draggable-front,
  #card-attr-tooltip-back, #card-attr-tooltip-front{
    user-select: none;
  }

 .front-card, .back-card{
  user-select: none;
  pointer-events: none
 }