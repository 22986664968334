
// Base Colors
$shade-10: #2c3e50 !default;
$shade-1: #d7dcdf !default;
$shade-0: #fff !default;
$steal: #4b5c6d !default;


// Range Slider
$range-width: 100% !default;
$range-handle-color: $shade-10 !default;
$range-handle-color-hover: $steal !default;
$range-handle-size: 25px !default;
$range-track-color: $shade-1 !default;
$range-track-height: 10px !default;
$range-label-color: $shade-10 !default;
$range-label-width: 65px !default;

.range-slider {
  width: $range-width;
}

.range-slider__range {
  -webkit-appearance: none;
  width: 100%;
  height: $range-track-height;
  border-radius: 5px;
  background: $range-track-color;
  outline: none;
  padding: 0;
  margin: 0;

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;
    
    border: 3px solid #fff;
    box-shadow: 0 0 0 3px #20c996;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $range-handle-color-hover;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    border: 3px solid #fff;
    box-shadow: 0 0 0 3px #20c996;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-moz-range-thumb, &:active::-moz-range-thumb {
    background: $range-handle-color-hover;
  }
  
  // Focus state
  &:focus {
    
    &::-webkit-slider-thumb, &::-moz-range-thumb {
      box-shadow: 0 0 0 3px $shade-0,
                  0 0 0 6px $teal;
    }
  }
}

// Range Label
// .range-slider__value {
//   display: inline-block;
//   position: relative;
//   width: $range-label-width;
//   color: $shade-0;
//   line-height: 20px;
//   text-align: center;
//   border-radius: 3px;
//   background: $range-label-color;
//   padding: 5px 10px;
//   margin-left: 8px;

//   &:after {
//     position: absolute;
//     top: 8px;
//     left: -7px;
//     width: 0;
//     height: 0;
//     border-top: 7px solid transparent;
//     border-right: 7px solid $range-label-color;
//     border-bottom: 7px solid transparent;
//     content: '';
//   }
// }

// Firefox Overrides
::-moz-range-track {
    background: transparent;
    border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer { 
  border: 0; 
}