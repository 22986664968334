.organization-tag {
    background: "red";
}

.icon-delete:hover {
    cursor: pointer;
}

.icon-delete-danger:hover {
    cursor: pointer;
    opacity: 1;
}

.icon-opacity {
    opacity: 0.5;
}

.icon-opacity:hover {
    opacity: 1;
    cursor: pointer;
}

.icon-margin {
  margin-right: 3px;
  margin-top: 3px;
}

.icon-top-right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
}