.hover-pointer:hover {
    cursor: pointer;
    color: #007bff;

}

.hover-icon:hover {
    color: #007bff;
}

.hover-pointer p {
    font-size: 20px;
}

.legend-label:hover {
    color: blue !important;
    cursor: pointer;
}

