/* MODAL STYLES
   ----------------------------- */

.modal {

	.modal-header {
		border-bottom: 0;
	}

	.modal-content {
		box-shadow: 0 4px 12px rgba(0,0,0,.2);
    	border: 0;
	}
	
	.modal-footer {
		border-top: 0;
	}
	
	.modal-content {
		border-radius: $cardRoundedCorner;
		@include cardShadow;
		padding: $panelPadding;
	}
	
	.modal-content-no-padding {
		border-radius: $cardRoundedCorner;
		@include cardShadow;
		padding: 0px;
		max-height: calc(90vh - 1rem);
	}

	.modal-body-no-scrollbar {
			overflow-y: auto;
			scrollbar-width: none;

	}
	
	&.fade {
		.modal-dialog {
			opacity: 0;
			transform: translate(0, -7%) scale(0.9);
			transition: all .25s ease;
		}

		&.show {
			.modal-dialog {
				opacity: 1;
				transform: translate(0, 0%) scale(1);
			}
		}
	}
}

.modal-backdrop {
	background-color: $modalOverlayBG;
}

.deleteModal .modal-content{
	background: linear-gradient(180deg, rgba(255,255,255,1) 35.5%, rgba(234,236,241,1) 36%, rgba(249,249,251,1) 36%);
}

.previewModal .modal-body-scrollable {
    max-height: 60vh;
    overflow-y: auto;
}

.previewModal .sticky-footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    padding-top: 10px; 
    padding-bottom: 10px;
}