/* TEXT COLOR STYLES
   ----------------------------- */

.text-inherit {
	color: inherit;
}
.text-black {
	color: black;
}
.text-white {
	color: white;
}