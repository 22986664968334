.react-datepicker-wrapper{
    width: 100%;
}
.react-datepicker-wrapper input{
    width: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    font-size: 13px;
    padding: 2px 10px;
    &:focus{
        border-radius: 5px;
        border-style: solid;
        border-width: 1px;
        border-color: #111;       
    }
}
.react-datepicker__tab-loop{
    .react-datepicker__month {
        margin: 0.7rem;
    }  
    .react-datepicker__year-wrapper{
        max-width: 236px;
    }
}
.datePicker{
    position: relative;
    .fa-calendar-plus-o{
        position: absolute;
        right: 10px;
        top: 10px;        
    }
}

.react-datepicker__navigation{
    overflow: visible;
}
.react-datepicker__navigation-icon--next::before{
    left: 15px;
}
.react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
    top: 15px;    
}
.react-datepicker__navigation-icon--previous::before{
    right: 30px;    
}
.react-datepicker__year .react-datepicker__year-text {
    width: 3rem;
}

.border_between{
    min-height: 8px;
    width: 0px;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgb(153, 153, 153);  
    flex-grow: 1;
    -webkit-box-flex: 1; 
    margin: auto;   
}
.operator_between{
    height: 31px;
    text-align: center; 
    background-color: rgb(242, 242, 242);     
    border: 1px rgb(153, 153, 153) solid;
    border-radius: 3px;
    padding:4px 8px; 
    color: rgb(61, 61, 61);
    font-size: 14px;
    display: block;
}
.operator_wrapper{
    width: 48px;
}
pre {
    margin-bottom: 1em;
    margin-top: 1em;
    overflow: auto;
    display: block;
    white-space: pre;
    background-color: #eeeeee;
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 11px;
    padding: 1rem;        
}


.ruleGroup .rule, .ruleGroup .ruleGroup {
    margin-top: 0.5rem;
    margin-left: 0;
}



.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: 0 1px 4px 0 rgba(0, 48, 73, 0.16);
    border-radius: 6px;
}

.jfBlxm, .datePicker .DayPickerInput > input{
    font-size: 14px;
    line-height: 24px;
    padding: 4px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //color: rgba(35, 31, 32, 1);
    border-radius: 6px;
    border: none;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 1px 4px 0 rgba(0, 48, 73, 0.16);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    cursor: pointer;
    margin-right: 10px;
    height: 32px;
    display: inline-block;

    @at-root {
        select#{&}, &[type="date"]{
            background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHdpZHRoPSIxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMy41NjU2ODU0MiA1LjQzNDMxNDU4LTEuMTMxMzcwODQgMS4xMzEzNzA4NCA1LjU2NTY4NTQyIDUuNTY1Njg1MzggNS41NjU2ODU0LTUuNTY1Njg1MzgtMS4xMzEzNzA4LTEuMTMxMzcwODQtNC40MzQzMTQ2IDQuNDM0MzE0NTd6IiBmaWxsPSIjMDAzMDQ5Ii8+PC9zdmc+");
            padding-right: 32px;
            display: inline-flex;            
        }  
    }  
    
    & label input{
        margin-right: 5px;
    }
}

.jfBlxm::-webkit-input-placeholder {
    color: rgba(35, 31, 32, 0.4);
}

.jfBlxm::-moz-placeholder {
    color: rgba(35, 31, 32, 0.4);
}

.jfBlxm:-ms-input-placeholder {
    color: rgba(35, 31, 32, 0.4);
}

.jfBlxm::placeholder {
    color: rgba(35, 31, 32, 0.4);
}

.jfBlxm::-ms-clear {
    display: none;
}

.jfBlxm[readonly] {
    cursor: default;
    color: rgba(35, 31, 32, 0.56);
}

.jfBlxm[disabled] {
    cursor: not-allowed;
    color: rgba(35, 31, 32, 0.56);
}

.jfBlxm:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(188, 220, 245, 0.4), 0 1px 4px 0 rgba(35, 31, 32, 0.08);
}

// .jfBlxm[type='number']::-webkit-inner-spin-button, .jfBlxm[type='number']::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }

.jfBlxm[type='number'] {
    -moz-appearance: textfield;
}

.jfBlxm::-ms-expand {
    display: none;
}



.DayPicker-Day{
    line-height: 18px;    
}

.DayPicker-Footer {
    text-align: center;
}

#month, #year{
    border: none;
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;    
}

.DayPickerInput-Overlay{
    border-radius: 6px;
}

.rule .react-dropdown-tree-select .dropdown .dropdown-content {
    z-index: 10;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 5px;
    margin-top: 3px;
    width: 100%;
    border: 1px hsl(0, 0%, 80%) solid,    
}
.rule .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after,
.rule .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    display: none;
}
.rule .react-dropdown-tree-select .dropdown .dropdown-trigger{
    padding: 0;
    border: none;
    width: 100%;
    line-height: 1;
    &:hover{
        text-decoration: none;
    }
}
.rule .react-dropdown-tree-select .tag-item input{
    width: 100%;
    border: none;
    margin-left: 4px;
    &::placeholder{
        color: hsl(0,0%,20%);
    }
}
.rule .react-dropdown-tree-select .tag-item{
    display: inline-block;
}
.rule .react-dropdown-tree-select .tag-item .tag{
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    border: none;
    padding: 1px;
    padding-left: 4px;
}
.rule .react-dropdown-tree-select .tag-item .tag button{
    font-size: 13px;
    font-weight: bold;
    color: #212629;
    margin-left: 4px;
    border-radius: 2px;
    &:hover{
        background-color: #FFBDAD;
        color: #DE350B;        
    }
}

.rule .react-dropdown-tree-select .dropdown{
    line-height: 1;
    width: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 32px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    font-size: 13px;
    padding: 0;

    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHdpZHRoPSIxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMy41NjU2ODU0MiA1LjQzNDMxNDU4LTEuMTMxMzcwODQgMS4xMzEzNzA4NCA1LjU2NTY4NTQyIDUuNTY1Njg1MzggNS41NjU2ODU0LTUuNTY1Njg1MzgtMS4xMzEzNzA4LTEuMTMxMzcwODQtNC40MzQzMTQ2IDQuNDM0MzE0NTd6IiBmaWxsPSIjMDAzMDQ5Ii8+PC9zdmc+");
}


.queryTag {
    margin-top: -13px;
    display: block;
    background: #4a4949;
    color: #fff;
    text-align: center;
    font-size: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;    
}

.ruleGroup {
    padding: 0.5rem;
    border: 1px solid gray;
    border-radius: 4px;
    background: #eeeeee;
}

.preCode_sql{
    max-width: 1091px;
    white-space: nowrap;
}

.preCode_json{
    max-width: 1091px;
}



.ant-select-selection-item .ant-select-selection-item-remove{
    -webkit-box-align: center;
    align-items: center;
    border-radius: 2px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    padding: 0 6px;
}

.ant-select-selection-item .ant-select-selection-item-remove:hover{
    background-color: rgb(255, 189, 173);
    color: rgb(222, 53, 11);
}
.ant-select-selection-overflow-item .ant-select-selection-item{
    padding-right: 0;
}

.date-interval-picker-date-option:hover {
    background: #216ba5 !important;
    color: #fff !important;
}

.date-interval-picker-date-wrapper .react-datepicker__navigation--next {
    right: -10px;
}
