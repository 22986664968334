.pendingMember {
  border: 1px transparent solid;
}

.pendingMember:has(.custom-control-input:checked) {
  border: 1px solid #7fb6f0;
  border-radius: 4px;
}

.cert-badge {
  width: 110px;
}

.hover-lift {
  transition: all 0.2s ease-in-out;
}

.hover-lift:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1) !important;
}

.certificate-modal .modal-content {
  border-radius: 10px;
}

.bg-soft-danger {
  background-color: #fff5f5;
}

.border-danger {
  border-color: #dc3545 !important;
}

.fade-card {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-card.fade-out {
  opacity: 0;
  transform: translateX(-20px);
  pointer-events: none; /* Disable interactions during transition */
}

/* Coffee */
.coffee-container {
  position: relative;
}

.steam-container {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 30px;
}

.squiggle-container {
  position: absolute;
  opacity: 0;
  animation: rise-and-fade 2.5s linear infinite;
}

.squiggle-container-1 {
  left: -10px;
  animation-delay: 0.2s;
}

.squiggle-container-2 {
  left: 0;
  animation-delay: 0s;
}

.squiggle-container-3 {
  left: 10px;
  animation-delay: 0.4s;
}

.squiggle-container svg {
  stroke: currentColor;
  stroke-width: 9;
  stroke-linecap: round;
  fill: none;
}

.fade-50{
  opacity: 0.5;
}

@keyframes rise-and-fade {
  0% {
      opacity: 0;
      transform: translateY(10px);
  }
  50% {
      opacity: 1;
  }
  75% {
      opacity: 0;
  }
  100% {
      opacity: 0;
      transform: translateY(-10px);
  }
}

.countryBadge {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  background-color: #f3f4f6;
  color: #1f2937;
}

.countryBadge.UK {
  background-color: #dbeafe;
  color: #1e40af;
}

.countryBadge.SE {
  background-color: #fecc0242;
  color: #613300;
}

.countryBadge.NO {
  background-color: #fff1f2;
  color: #BA0C2F;
}

.countryBadge.FR {
  background-color: #e8e8ff;
  color: #000095;
}

.countryBadge.PL {
  background-color: #fee2e2;
  color: #DC143C;
}