.Member {
    cursor: pointer;
}

a.member-card-link:hover{
    text-decoration: none;
    .card-body{
    transform: translateY(-1px);
    box-shadow: 0 5px 10px -5px rgba(50,50,93,0.25), 0 3px 10px -8px rgba(0,0,0,0.3), 0 -6px 8px -6px rgba(0,0,0,0.025);
    transition: all 0.2s ease;  
    }      
}

.list-item-heading {
    color: #888888;        
    font-size: 10px;
}   

.list-item-text{
    font-size: 12px;
    color: #000b27;
}

.list-item-text-small {
    font-size: 10px;
    color: #000b27;
}
 
.studyPace_info{
    border-radius: 5px;
    padding: 14px;
    text-align: center;
    font-size: 13px;
    &.verified{
        background: #d1ffe7;
        color: #22b66e;
        border: 1px #22b66e solid;    
    }
    &.notVerified{
        background: #f9bcbc;
        color: #861c1c;
        border: 1px #861c1c solid;    
    } 
    &.verifiedNotEligible{
        background: #fbe6b5;
        color: #a77600;
        border: 1px #a77600 solid;    
    }       
}

.percent{
    color:#cccbcd;
    border: 1px #cccbcd solid;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;

    &.b-green{
        color:#22b66e;
        border-color:#22b66e;
    }
    &.b-red{
        color:#ff1f17;
        border-color:#ff1f17;
    }
    &.b-orange{
        color:#ffaa3b;
        border-color:#ffaa3b;
    }    
}

.alert-gray{
    background: #e9ecef;
}