/* MEDIA STYLES
   ----------------------------- */
.attachment-preview {
    position: relative;
    box-shadow: inset 0 0 15px rgba(0,0,0, 0.10), inset 0 0 0 1px rgba(0,0,0, 0.05);
    background: #f8f8f8;
    cursor: pointer;
    width: 130px;
}

.attachment-preview:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.thumbnail {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: opacity .1s;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.mediaWrap{
  padding: 8px;
}

.activeMedia{
  box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 7px #2271b1;
}

.centered {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(50%,50%);
}

.thumbnail:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 1px rgba(0,0,0, 0.10);
  overflow: hidden;
}

.thumbnail img{
  transform: translate(-50%,-50%);
  object-fit: cover;
  object-position: center;
  height: 130px;
  width: 130px;  
}