/* TYPOGRAPHY STYLES
   ----------------------------- */

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6  {
	margin-top: 0;
}
h1,
.h1{
  font-size: $h1Size;
}
h2,
.h2{
  font-size: $h2Size;
}
h3,
.h3{
  font-size: $h3Size;
}
h4,
.h4{
  font-size: $h4Size;
}
h5,
.h5{
  font-size: $h5Size;
}
h6,
.h6{
  font-size: $h6Size;
}

.error-number {
  color: #22262e;
  font-size: 120px;
  font-weight: 600;
  line-height: 130px;
}

.back-to-link {
	position: absolute;
	top: 20px;
	left: 40px;
}

.fa-icon-small {
  font-size: 8px;
}
