/* CARD STYLES
   ----------------------------- */

.card {
	background: white;
	border-radius: $cardRoundedCorner;
	@include cardShadow;
	border: 0;
	margin-bottom: 20px;

	hr {
		margin-left: -$panelPadding;
		margin-right: -$panelPadding;
		border-color: #ebeef2; 
	}

	.btn-success{
		margin: 0 15px 15px;
	}
}

.card-header,
.card-footer {
	color: $panelHeadingColor;
	padding: $panelPadding $panelPadding;
	border-color: transparent;
	position: relative;
	font-size: $panelHeadingFontSize;
	background-color: transparent;
}

.card-header {
	padding-bottom: 0;
}

.card-body {
	padding: $panelPadding $panelPadding;
}

.card-footer {
	border-color: #dcdcdc;
}

.card-expanded {
	height: auto;
}

.card-disabled {
	pointer-events: none;
}

.card-footer-left-item {
	position: absolute; 
	bottom: 0;
	left: 0;
	font-size: xx-small; 
	margin-left: 20px;
	color: #888;
}

// &.bg-transparent {
// 	background: transparent;
// 	box-shadow: none;

// 	.panel-heading {
// 		background: transparent;
// 		border-bottom: 0;
// 	}
// }

