.badge {
	border-radius: $badgeBorderRadius;
	line-height: inherit;
}

.badge-tag {
	border-radius: 4px;
    transition: all 0.15s ease;
    font-size: 10px;
    font-weight: 600;
}

.tag-pending-add {
	background-color: $alertBgColorSuccess;
}

.tag-pending-delete {
	background-color: #E74C4B;
}
.badge-corner {
	position: absolute;
    right: 0;
    top: 0;
    margin: 5px
}
