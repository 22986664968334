/* SPACER UTILITY STYLES
   ----------------------------- */

$baseSpacer: 16px;

$xxs:  1px;
$xs:   $baseSpacer * .25;
$sm:   $baseSpacer * .5;
$n:   $baseSpacer;
$md:  $baseSpacer * 1.5;
$lg:  $baseSpacer * 2;
$xl:  $baseSpacer * 2.5;
$xxl: $baseSpacer * 3;

//Margins
// All
.m-a-none {
  margin: 0 !important;
}

.m-a-xxs {
  margin: $xxs;
}

.m-a-xs {
  margin: $xs;
}

.m-a-sm {
  margin: $sm;
}

.m-a {
  margin: $n;
}

.m-a-md {
  margin: $md;
}

.m-a-lg {
  margin: $lg;
}

.m-a-xl {
  margin: $xl;
}

.m-a-xxl {
  margin: $xxl;
}

.m-a-n-xxs {
  margin: -$xxs;
}

.m-a-n-xs {
  margin: -$xs;
}

.m-a-n-sm {
  margin: -$sm;
}

.m-a-n {
  margin: -$n;
}

.m-a-n-md {
  margin: -$md;
}

.m-a-n-lg {
  margin: -$lg;
}

.m-a-n-xl {
  margin: -$xl;
}

.m-a-n-xxl {
  margin: -$xxl;
}

//top
.m-t-none {
  margin-top: 0 !important;
}

.m-t-xxs {
  margin-top: $xxs;
}

.m-t-xs {
  margin-top: $xs;
}

.m-t-sm {
  margin-top: $sm;
}

.m-t {
  margin-top: $n;
}

.m-t-md {
  margin-top: $md;
}

.m-t-lg {
  margin-top: $lg;
}

.m-t-xl {
  margin-top: $xl;
}

.m-t-xxl {
  margin-top: $xxl;
}

.m-t-n-xxs {
  margin-top: -$xxs;
}

.m-t-n-xs {
  margin-top: -$xs;
}

.m-t-n-sm {
  margin-top: -$sm;
}

.m-t-n {
  margin-top: -$n;
}

.m-t-n-md {
  margin-top: -$md;
}

.m-t-n-lg {
  margin-top: -$lg;
}

.m-t-n-xl {
  margin-top: -$xl;
}

.m-t-n-xxl {
  margin-top: -$xxl;
}

//Right
.m-r-none {
  margin-right: 0 !important;
}

.m-r-xxs {
  margin-right: $xxs;
}

.m-r-xs {
  margin-right: $xs;
}

.m-r-sm {
  margin-right: $sm;
}

.m-r {
  margin-right: $n;
}

.m-r-md {
  margin-right: $md;
}

.m-r-lg {
  margin-right: $lg;
}

.m-r-xl {
  margin-right: $xl;
}

.m-r-xxl {
  margin-right: $xxl;
}

.m-r-n-xxs {
  margin-right: -$xxs;
}

.m-r-n-xs {
  margin-right: -$xs;
}

.m-r-n-sm {
  margin-right: -$sm;
}

.m-r-n {
  margin-right: -$n;
}

.m-r-n-md {
  margin-right: -$md;
}

.m-r-n-lg {
  margin-right: -$lg;
}

.m-r-n-xl {
  margin-right: -$xl;
}

.m-r-n-xxl {
  margin-right: -$xxl;
}

//bottom
.m-b-none {
  margin-bottom: 0 !important;
}

.m-b-xxs {
  margin-bottom: $xxs;
}

.m-b-xs {
  margin-bottom: $xs;
}

.m-b-sm {
  margin-bottom: $sm;
}

.m-b {
  margin-bottom: $n;
}

.m-b-md {
  margin-bottom: $md;
}

.m-b-lg {
  margin-bottom: $lg;
}

.m-b-xl {
  margin-bottom: $xl;
}

.m-b-xxl {
  margin-bottom: $xxl;
}

.m-b-n-xxs {
  margin-bottom: -$xxs;
}

.m-b-n-xs {
  margin-bottom: -$xs;
}

.m-b-n-sm {
  margin-bottom: -$sm;
}

.m-b-n {
  margin-bottom: -$n;
}

.m-b-n-md {
  margin-bottom: -$md;
}

.m-b-n-lg {
  margin-bottom: -$lg;
}

.m-b-n-xl {
  margin-bottom: -$xl;
}

.m-b-n-xxl {
  margin-bottom: -$xxl;
}
//margin left
.m-l-none {
  margin-left: 0 !important;
}

.m-l-xxs {
  margin-left: $xxs;
}

.m-l-xs {
  margin-left: $xs;
}

.m-l-sm {
  margin-left: $sm;
}

.m-l {
  margin-left: $n;
}

.m-l-md {
  margin-left: $md;
}

.m-l-lg {
  margin-left: $lg;
}

.m-l-xl {
  margin-left: $xl;
}

.m-l-xxl {
  margin-left: $xxl;
}

.m-l-n-xxs {
  margin-left: -$xxs;
}

.m-l-n-xs {
  margin-left: -$xs;
}

.m-l-n-sm {
  margin-left: -$sm;
}

.m-l-n {
  margin-left: -$n;
}

.m-l-n-md {
  margin-left: -$md;
}

.m-l-n-lg {
  margin-left: -$lg;
}

.m-l-n-xl {
  margin-left: -$xl;
}

.m-l-n-xxl {
  margin-left: -$xxl;
}

//Paddings
// All
.p-a-none {
  padding: 0 !important;
}

.p-a-xxs {
  padding: $xxs;
}

.p-a-xs {
  padding: $xs;
}

.p-a-sm {
  padding: $sm;
}

.p-a {
  padding: $n;
}

.p-a-md {
  padding: $md;
}

.p-a-lg {
  padding: $lg;
}

.p-a-xl {
  padding: $xl;
}

.p-a-xxl {
  padding: $xxl;
}

.p-a-n-xxs {
  padding: -$xxs;
}

.p-a-n-xs {
  padding: -$xs;
}

.p-a-n-sm {
  padding: -$sm;
}

.p-a-n {
  padding: -$n;
}

.p-a-n-md {
  padding: -$md;
}

.p-a-n-lg {
  padding: -$lg;
}

.p-a-n-xl {
  padding: -$xl;
}

.p-a-n-xxl {
  padding: -$xxl;
}
//top
.p-t-none {
  padding-top: 0 !important;
}

.p-t-xxs {
  padding-top: $xxs;
}

.p-t-xs {
  padding-top: $xs;
}

.p-t-sm {
  padding-top: $sm;
}

.p-t {
  padding-top: $n;
}

.p-t-md {
  padding-top: $md;
}

.p-t-lg {
  padding-top: $lg;
}

.p-t-xl {
  padding-top: $xl;
}

.p-t-xxl {
  padding-top: $xxl;
}

.p-t-n-xxs {
  padding-top: -$xxs;
}

.p-t-n-xs {
  padding-top: -$xs;
}

.p-t-n-sm {
  padding-top: -$sm;
}

.p-t-n {
  padding-top: -$n;
}

.p-t-n-md {
  padding-top: -$md;
}

.p-t-n-lg {
  padding-top: -$lg;
}

.p-t-n-xl {
  padding-top: -$xl;
}

.p-t-n-xxl {
  padding-top: -$xxl;
}

//Right
.p-r-none {
  padding-right: 0 !important;
}

.p-r-xxs {
  padding-right: $xxs;
}

.p-r-xs {
  padding-right: $xs;
}

.p-r-sm {
  padding-right: $sm;
}

.p-r {
  padding-right: $n;
}

.p-r-md {
  padding-right: $md;
}

.p-r-lg {
  padding-right: $lg;
}

.p-r-xl {
  padding-right: $xl;
}

.p-r-xxl {
  padding-right: $xxl;
}

.p-r-n-xxs {
  padding-right: -$xxs;
}

.p-r-n-xs {
  padding-right: -$xs;
}

.p-r-n-sm {
  padding-right: -$sm;
}

.p-r-n {
  padding-right: -$n;
}

.p-r-n-md {
  padding-right: -$md;
}

.p-r-n-lg {
  padding-right: -$lg;
}

.p-r-n-xl {
  padding-right: -$xl;
}

.p-r-n-xxl {
  padding-right: -$xxl;
}

//bottom
.p-b-none {
  padding-bottom: 0 !important;
}

.p-b-xxs {
  padding-bottom: $xxs;
}

.p-b-xs {
  padding-bottom: $xs;
}

.p-b-sm {
  padding-bottom: $sm;
}

.p-b {
  padding-bottom: $n;
}

.p-b-md {
  padding-bottom: $md;
}

.p-b-lg {
  padding-bottom: $lg;
}

.p-b-xl {
  padding-bottom: $xl;
}

.p-b-xxl {
  padding-bottom: $xxl;
}

.p-b-n-xxs {
  padding-bottom: -$xxs;
}

.p-b-n-xs {
  padding-bottom: -$xs;
}

.p-b-n-sm {
  padding-bottom: -$sm;
}

.p-b-n {
  padding-bottom: -$n;
}

.p-b-n-md {
  padding-bottom: -$md;
}

.p-b-n-lg {
  padding-bottom: -$lg;
}

.p-b-n-xl {
  padding-bottom: -$xl;
}

.p-b-n-xxl {
  padding-bottom: -$xxl;
}
//padding left
.p-l-none {
  padding-left: 0 !important;
}

.p-l-xxs {
  padding-left: $xxs;
}

.p-l-xs {
  padding-left: $xs;
}

.p-l-sm {
  padding-left: $sm;
}

.p-l {
  padding-left: $n;
}

.p-l-md {
  padding-left: $md;
}

.p-l-lg {
  padding-left: $lg;
}

.p-l-xl {
  padding-left: $xl;
}

.p-l-xxl {
  padding-left: $xxl;
}

.p-l-n-xxs {
  padding-left: -$xxs;
}

.p-l-n-xs {
  padding-left: -$xs;
}

.p-l-n-sm {
  padding-left: -$sm;
}

.p-l-n {
  padding-left: -$n;
}

.p-l-n-md {
  padding-left: -$md;
}

.p-l-n-lg {
  padding-left: -$lg;
}

.p-l-n-xl {
  padding-left: -$xl;
}

.p-l-n-xxl {
  padding-left: -$xxl;
}
