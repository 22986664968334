.theme-block {
  background: white;
  border-radius: 8px;
  padding: 0.3rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border: 1px solid #e2e8f0;
}

.block-title {
  font-weight: 600;
  color: #2d3748;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.block-content {
  border-top: 1px #edf2f7 solid;
  border-radius: 0;
  padding-top: 1rem;
}

.elementborder{
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: none;
}

[data-rbd-drag-handle-context-id]:last-child .elementborder {
  margin-bottom: 0;
}

.row:has(+ [data-rbd-droppable-id]:empty) {
  margin: 0 !important;
}

.theme-block .card-body{
  padding-top: 0;
  .elementsArea{
    padding: 1rem;
    background: #f8fafc;
    border-radius: 6px;

    .form-group{
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }
  }
}


.btn-icon {
  padding: 0.5rem;
  border-radius: 4px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #718096;
  transition: all 0.2s;
}

.btn-icon:hover {
  background: #edf2f7;
  color: #4a5568;
}

.icon {
  width: 20px;
  height: 20px;
}

.sidebar-sticky {
    position: sticky;
    top: 5rem;
    align-self: flex-start;
}

.back-block-btn{
  margin-top: 3rem;
}



/* Define a more elegant highlight animation */
@keyframes simpleHighlight {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #00035d;
  }
  100% {
    border-color: transparent;
 
  }
}

.highlight {
  animation: simpleHighlight 1.5s ease-in-out;
  border-width: 1px;
  border-style: solid;
}


.draggable-front, 
.draggable-back{
  background:
  linear-gradient(to right, black 1px, transparent 1px) 0 0,
  linear-gradient(to right, black 1px, transparent 1px) 0 100%,
  linear-gradient(to left, black 1px, transparent 1px) 100% 0,
  linear-gradient(to left, black 1px, transparent 1px) 100% 100%,
  linear-gradient(to bottom, black 1px, transparent 1px) 0 0,
  linear-gradient(to bottom, black 1px, transparent 1px) 100% 0,
  linear-gradient(to top, black 1px, transparent 1px) 0 100%,
  linear-gradient(to top, black 1px, transparent 1px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 5px 5px;

}

.draggable-front:hover, 
.draggable-back:hover{
  background:
  linear-gradient(to right, rgb(0, 132, 255) 1px, transparent 1px) 0 0,
  linear-gradient(to right, rgb(0, 132, 255) 1px, transparent 1px) 0 100%,
  linear-gradient(to left, rgb(0, 132, 255) 1px, transparent 1px) 100% 0,
  linear-gradient(to left, rgb(0, 132, 255) 1px, transparent 1px) 100% 100%,
  linear-gradient(to bottom, rgb(0, 132, 255) 1px, transparent 1px) 0 0,
  linear-gradient(to bottom, rgb(0, 132, 255) 1px, transparent 1px) 100% 0,
  linear-gradient(to top, rgb(0, 132, 255) 1px, transparent 1px) 0 100%,
  linear-gradient(to top, rgb(0, 132, 255) 1px, transparent 1px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 7px 7px;
}

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-size: 16px 16px; /* Adjust the grid size as needed */
  background-image: linear-gradient(to right, rgba(218, 218, 218, 0.3) 1px, transparent 1px),
                    linear-gradient(to bottom, rgba(218, 218, 218, 0.3) 1px, transparent 1px);
}

.btn-icon.active {
  background-color: #007bff; /* Change to your desired active background color */
  color: white;
}

 