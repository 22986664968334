/* TYPOGRAPHY UTILITY STYLES
   ----------------------------- */

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-through {
	text-decoration: line-through;
}
.bold {
  font-weight: bold;
}
.bold-400 {
  font-weight: 400;
}
.bold-600 {
  font-weight: 600;
}
.bold-700 {
  font-weight: 700;
}
.all-caps {
  text-transform: uppercase;
}
.hint-text {
    opacity: .7;
}
.text-ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}