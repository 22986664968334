.rc-time-picker .rc-time-picker-input{
    width: 111px;
    font-size: 14px;
    border-radius: 6px;
    border: none;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 1px 4px 0 rgba(0, 48, 73, 0.16);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    cursor: pointer;
    margin-right: 10px;
    height: 32px;
    display: inline-block;
    outline: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHdpZHRoPSIxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMy41NjU2ODU0MiA1LjQzNDMxNDU4LTEuMTMxMzcwODQgMS4xMzEzNzA4NCA1LjU2NTY4NTQyIDUuNTY1Njg1MzggNS41NjU2ODU0LTUuNTY1Njg1MzgtMS4xMzEzNzA4LTEuMTMxMzcwODQtNC40MzQzMTQ2IDQuNDM0MzE0NTd6IiBmaWxsPSIjMDAzMDQ5Ii8+PC9zdmc+);
}

.rc-time-picker-input[disabled] {
    background-color: #e9ecef;
    cursor: default;
    color: #212529;
    border: 1px solid #d5dce6;
    background-image: none;
}