.tooltip-inner {
	border-radius: $tooltipRoundedCorner;
}

#card-attr-tooltip::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.button-tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.button-tooltip .button-tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	text-transform: none;
	transition: opacity 0.3s;
}

.button-tooltip .button-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.button-tooltip:hover .button-tooltiptext {
	visibility: visible;
	opacity: 1;
}

// tooltip below
.button-tooltip .button-tooltiptext.tooltip-below {
	bottom: auto;
	top: 125%;
	left: 50%;
	margin-left: -60px;
}

// tooltip arrow below
.button-tooltip .button-tooltiptext.tooltip-below::after {
	top: auto;
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-color: transparent transparent #555 transparent;
}