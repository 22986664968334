/* LOGIN STYLES
   ----------------------------- */

.login-page-wrapper {
    position: relative;
    min-height: 100%;
    z-index: 1;
}

.login-wrap {
    width: auto;
    max-width: 400px;
    display: block;
    margin-top: 30px;
    margin: auto;
}


.auth-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;

    .auth-content {
        position: relative;
        width: 390px;
        padding: 15px;
        z-index: 5;
    }

    .auth-icon {
        background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 auto;
        line-height: 32px;
    }
}

p.text-muted {
    font-size: 13px;
}

.resetMessage {
    height: 300px;
    display: flex;
    align-items: center;
}

.auth-bg {
    .r {
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;

        &:first-child {
            top: -100px;
            right: -100px;
            background: linear-gradient(-135deg, #1de9b6, #1dc4e9);
        }

        &:last-child {
            left: -100px;
            bottom: -100px;
            background: linear-gradient(-135deg, #899fd4, #a389d4);
        }

        &.s {
            width: 20px;
            height: 20px;

            &:nth-child(2) {
                top: 150px;
                right: -150px;
                background: #04a9f5;
            }

            &:nth-child(3) {
                left: -150px;
                bottom: 150px;
                background: #1de9b6;
            }
        }

        &:nth-child(odd) {
            animation: floating 7s infinite;
        }

        &:nth-child(even) {
            animation: floating 9s infinite;
        }
    }
}

@keyframes floating {
    from {
        transform: rotate(0deg) translate(-10px) rotate(0deg);
    }

    to {
        transform: rotate(360deg) translate(-10px) rotate(-360deg);
    }
}